var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tOfficeTermList" },
    [
      _c(
        "Card",
        { staticClass: "d-card" },
        [
          _c(
            "Row",
            { attrs: { type: "flex", justify: "space-between", wrap: false } },
            [
              _vm.expand
                ? _c(
                    "Col",
                    {
                      staticStyle: { position: "relative" },
                      attrs: { span: _vm.leftSpan },
                    },
                    [
                      _c("Alert", { attrs: { "show-icon": "" } }, [
                        _vm._v(" 当前选择： "),
                        _c("span", { staticClass: "select-title" }, [
                          _vm._v(_vm._s(_vm.editTitle)),
                        ]),
                        _c("br"),
                        _vm.editTitle
                          ? _c(
                              "a",
                              {
                                staticClass: "select-clear",
                                on: { click: _vm.cancelEdit },
                              },
                              [_vm._v("取消选择")]
                            )
                          : _vm._e(),
                      ]),
                      _c("Input", {
                        attrs: {
                          suffix: "ios-search",
                          placeholder: "输入搜索科室",
                          clearable: "",
                        },
                        on: { "on-change": _vm.search },
                        model: {
                          value: _vm.searchKey,
                          callback: function ($$v) {
                            _vm.searchKey = $$v
                          },
                          expression: "searchKey",
                        },
                      }),
                      _c("div", { staticStyle: { position: "relative" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "tree-bar",
                            style: { maxHeight: _vm.height + 100 + "px" },
                          },
                          [
                            _c("Tree", {
                              ref: "tree",
                              attrs: { data: _vm.treeData },
                              on: { "on-select-change": _vm.selectTree },
                            }),
                            _vm.treeLoading
                              ? _c("Spin", {
                                  attrs: { size: "large", fix: "" },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "expand" },
                [
                  _c("Icon", {
                    staticClass: "icon",
                    attrs: { type: _vm.expandIcon, size: "16" },
                    on: { click: _vm.changeExpand },
                  }),
                ],
                1
              ),
              _c(
                "Col",
                { attrs: { span: _vm.span } },
                [
                  _c(
                    "Row",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.openSearch,
                          expression: "openSearch",
                        },
                      ],
                      nativeOn: {
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          $event.preventDefault()
                          return _vm.handSearch.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "Form",
                        {
                          ref: "searchForm",
                          attrs: {
                            model: _vm.searchForm,
                            inline: "",
                            "label-width": 80,
                          },
                          nativeOn: {
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.handSearch.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "Form-item",
                            { attrs: { label: "体检类型" } },
                            [
                              _c(
                                "Select",
                                {
                                  directives: [
                                    {
                                      name: "width",
                                      rawName: "v-width",
                                      value: 150,
                                      expression: "150",
                                    },
                                  ],
                                  attrs: {
                                    placeholder: "请选择体检类型",
                                    multiple: "",
                                    transfer: "",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.inspectType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "inspectType",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.inspectType",
                                  },
                                },
                                _vm._l(
                                  _vm.inspectTypePriority,
                                  function (item, i) {
                                    return _c(
                                      "Option",
                                      {
                                        key: item.id,
                                        attrs: { value: item.value },
                                      },
                                      [_vm._v(_vm._s(item.title))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "Form-item",
                            { attrs: { label: "术语类型" } },
                            [
                              _c(
                                "Select",
                                {
                                  directives: [
                                    {
                                      name: "width",
                                      rawName: "v-width",
                                      value: 150,
                                      expression: "150",
                                    },
                                  ],
                                  attrs: {
                                    placeholder: "请选择术语类型",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.searchForm.type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "type", $$v)
                                    },
                                    expression: "searchForm.type",
                                  },
                                },
                                _vm._l(
                                  _vm.officeTermTypePriority,
                                  function (item, i) {
                                    return _c(
                                      "Option",
                                      {
                                        key: item.id,
                                        attrs: { value: item.value },
                                      },
                                      [_vm._v(_vm._s(item.title))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "Form-item",
                            { attrs: { label: "危害因素" } },
                            [
                              _c("TreeSelect", {
                                directives: [
                                  {
                                    name: "width",
                                    rawName: "v-width",
                                    value: 150,
                                    expression: "150",
                                  },
                                ],
                                ref: "hazardFactors",
                                attrs: {
                                  data: _vm.hazardFactorsPriority,
                                  clearable: "",
                                },
                                on: { "on-change": _vm.hazardFactorsChange },
                                model: {
                                  value: _vm.searchForm.hazardFactors,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchForm,
                                      "hazardFactors",
                                      $$v
                                    )
                                  },
                                  expression: "searchForm.hazardFactors",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "Form-item",
                            { attrs: { label: "在岗状态" } },
                            [
                              _c(
                                "Select",
                                {
                                  directives: [
                                    {
                                      name: "width",
                                      rawName: "v-width",
                                      value: 150,
                                      expression: "150",
                                    },
                                  ],
                                  attrs: {
                                    placeholder: "请选择在岗状态",
                                    multiple: "",
                                    clearable: "",
                                    transfer: "",
                                    "label-in-value": "",
                                  },
                                  on: { "on-change": _vm.workChange },
                                  model: {
                                    value: _vm.searchForm.workStateCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "workStateCode",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.workStateCode",
                                  },
                                },
                                _vm._l(
                                  _vm.workStateCodeArr,
                                  function (item, index) {
                                    return _c(
                                      "Option",
                                      {
                                        key: item.id,
                                        attrs: { value: item.value },
                                      },
                                      [_vm._v(_vm._s(item.title))]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.drop
                            ? _c(
                                "Form-item",
                                { attrs: { label: "术语内容" } },
                                [
                                  _c("Input", {
                                    directives: [
                                      {
                                        name: "width",
                                        rawName: "v-width",
                                        value: 150,
                                        expression: "150",
                                      },
                                    ],
                                    attrs: { placeholder: "术语内容" },
                                    model: {
                                      value: _vm.searchForm.content,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.searchForm, "content", $$v)
                                      },
                                      expression: "searchForm.content",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.drop
                            ? _c(
                                "Form-item",
                                { attrs: { label: "创建时间" } },
                                [
                                  _c("DatePicker", {
                                    directives: [
                                      {
                                        name: "width",
                                        rawName: "v-width",
                                        value: 150,
                                        expression: "150",
                                      },
                                    ],
                                    attrs: {
                                      type: "daterange",
                                      format: "yyyy-MM-dd",
                                      clearable: "",
                                      placeholder: "选择起始时间",
                                    },
                                    on: { "on-change": _vm.selectDateRange },
                                    model: {
                                      value: _vm.selectDate,
                                      callback: function ($$v) {
                                        _vm.selectDate = $$v
                                      },
                                      expression: "selectDate",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "Form-item",
                            {
                              staticClass: "br",
                              staticStyle: { "margin-left": "-35px" },
                            },
                            [
                              _c(
                                "Button",
                                {
                                  attrs: {
                                    type: "primary",
                                    icon: "ios-search",
                                  },
                                  on: { click: _vm.handSearch },
                                },
                                [_vm._v("搜索")]
                              ),
                              _c("Button", { on: { click: _vm.handReset } }, [
                                _vm._v("重置"),
                              ]),
                              _c(
                                "a",
                                {
                                  staticClass: "drop-down",
                                  on: { click: _vm.dropDown },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.dropDownContent) + " "
                                  ),
                                  _c("Icon", {
                                    attrs: { type: _vm.dropDownIcon },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "operation" },
                    [
                      _c(
                        "Button",
                        {
                          attrs: { type: "primary", icon: "md-add" },
                          on: { click: _vm.addTOfficeTerm },
                        },
                        [_vm._v("新增")]
                      ),
                      _c(
                        "Dropdown",
                        { on: { "on-click": _vm.handDropdown } },
                        [
                          _c(
                            "Button",
                            [
                              _vm._v(" 更多操作 "),
                              _c("Icon", {
                                attrs: { type: "md-arrow-dropdown" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "DropdownMenu",
                            { attrs: { slot: "list" }, slot: "list" },
                            [
                              _c(
                                "DropdownItem",
                                { attrs: { name: "refresh" } },
                                [
                                  _c("Icon", { attrs: { type: "md-sync" } }),
                                  _vm._v(" 刷新 "),
                                ],
                                1
                              ),
                              _c(
                                "DropdownItem",
                                { attrs: { name: "removeAll" } },
                                [
                                  _c("Icon", { attrs: { type: "md-trash" } }),
                                  _vm._v(" 批量删除 "),
                                ],
                                1
                              ),
                              _c(
                                "DropdownItem",
                                { attrs: { name: "export" } },
                                [
                                  _c("Icon", {
                                    attrs: { type: "md-arrow-down" },
                                  }),
                                  _vm._v(" 导出本页数据 "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Button",
                        {
                          attrs: { type: "dashed" },
                          on: {
                            click: function ($event) {
                              _vm.openSearch = !_vm.openSearch
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.openSearch ? "关闭搜索" : "开启搜索")
                          ),
                        ]
                      ),
                      _c(
                        "Button",
                        {
                          attrs: { type: "dashed" },
                          on: {
                            click: function ($event) {
                              _vm.openTip = !_vm.openTip
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.openTip ? "关闭提示" : "开启提示"))]
                      ),
                      _c(
                        "div",
                        { staticStyle: { float: "right" } },
                        [
                          _c(
                            "Poptip",
                            {
                              attrs: {
                                transfer: "",
                                trigger: "click",
                                placement: "bottom-end",
                                title: "动态列",
                              },
                            },
                            [
                              _c("Button", { attrs: { icon: "md-list" } }),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    position: "relative",
                                    "min-height": "5vh",
                                  },
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [
                                  _c(
                                    "Checkbox-group",
                                    {
                                      on: { "on-change": _vm.checkboxChange },
                                      model: {
                                        value: _vm.colOptions,
                                        callback: function ($$v) {
                                          _vm.colOptions = $$v
                                        },
                                        expression: "colOptions",
                                      },
                                    },
                                    _vm._l(_vm.colSelect, function (item, i) {
                                      return _c(
                                        "row",
                                        { key: i },
                                        [
                                          _c("checkbox", {
                                            staticStyle: {
                                              "margin-bottom": "5px",
                                              "font-weight": "700",
                                            },
                                            attrs: { label: item },
                                          }),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.openTip,
                          expression: "openTip",
                        },
                      ],
                    },
                    [
                      _c("Alert", { attrs: { "show-icon": "" } }, [
                        _vm._v(" 已选择 "),
                        _c("span", { staticClass: "select-count" }, [
                          _vm._v(_vm._s(_vm.selectCount)),
                        ]),
                        _vm._v(" 项 "),
                        _c(
                          "a",
                          {
                            staticClass: "select-clear",
                            on: { click: _vm.clearSelectAll },
                          },
                          [_vm._v("清空")]
                        ),
                        _c("span", { staticStyle: { float: "right" } }, [
                          _vm._v("点击右上角按钮配置动态列↑"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("Table", {
                        ref: "table",
                        attrs: {
                          "max-height": _vm.height,
                          loading: _vm.loading,
                          border: "",
                          columns: _vm.columns,
                          sortable: "custom",
                          data: _vm.data,
                        },
                        on: {
                          "on-sort-change": _vm.changeSort,
                          "on-selection-change": _vm.showSelect,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "action",
                            fn: function ({ row, index }) {
                              return [
                                _c(
                                  "Button",
                                  {
                                    attrs: { type: "primary", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handLook(row, index)
                                      },
                                    },
                                  },
                                  [
                                    _c("Icon", {
                                      attrs: { type: "ios-eye-outline" },
                                    }),
                                    _vm._v(" 查看 "),
                                  ],
                                  1
                                ),
                                _c(
                                  "Dropdown",
                                  { attrs: { transfer: true } },
                                  [
                                    _c(
                                      "Button",
                                      { attrs: { size: "small" } },
                                      [
                                        _vm._v(" 更多操作 "),
                                        _c("Icon", {
                                          attrs: { type: "md-arrow-dropdown" },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "DropdownMenu",
                                      { attrs: { slot: "list" }, slot: "list" },
                                      [
                                        _c(
                                          "DropdownItem",
                                          {
                                            attrs: { name: "edit" },
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.handEdit(row, index)
                                              },
                                            },
                                          },
                                          [
                                            _c("Icon", {
                                              attrs: {
                                                type: "ios-create-outline",
                                              },
                                            }),
                                            _vm._v(" 修改 "),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "DropdownItem",
                                          {
                                            attrs: { name: "delete" },
                                            nativeOn: {
                                              click: function ($event) {
                                                return _vm.deleteData(
                                                  row,
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("Icon", {
                                              attrs: { type: "md-trash" },
                                            }),
                                            _vm._v(" 删除 "),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "Row",
                    {
                      staticClass: "page",
                      attrs: { type: "flex", justify: "end" },
                    },
                    [
                      _c("Page", {
                        attrs: {
                          current: _vm.searchForm.pageNumber,
                          total: _vm.total,
                          "page-size": _vm.searchForm.pageSize,
                          "page-size-opts": [10, 20, 50],
                          size: "small",
                          "show-total": "",
                          "show-elevator": "",
                          "show-sizer": "",
                        },
                        on: {
                          "on-change": _vm.changePage,
                          "on-page-size-change": _vm.changePageSize,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("updateTOfficeTerm", {
        attrs: {
          TOfficeTermId: _vm.TOfficeTermId,
          TOfficeId: _vm.TOfficeId,
          modalTitle: _vm.title,
        },
        on: { handSearch: _vm.handSearch },
        model: {
          value: _vm.updateShow,
          callback: function ($$v) {
            _vm.updateShow = $$v
          },
          expression: "updateShow",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }